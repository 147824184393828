<template>
  <div id="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer v-if="showFooter" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  metaInfo() {
    /* eslint-disable max-len, object-curly-newline */
    return {
      htmlAttrs: {
        // lang: this.locale,
        lang: this.$i18n.locale,
      },
      titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} | Career Craft` : 'Career Craft'),
      meta: [
        // { charset: 'utf-8' },
        // { name: 'description', content: '' },
        { 'http-equiv': 'Content-Type', content: 'text/html', charset: 'UTF-8' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        // Facebook OpenGraph
        { property: 'og:url', content: `https://careercraft.jp${this.$route.fullPath}` },
        { property: 'og:description', content: '' },
        { property: 'og:image', content: 'https://careercraft.jp/careercraft.jpg' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: this.locale === 'ja' ? 'jp_JA' : 'en_US' },
        { property: 'fb:app_id', content: '820193635133255' },
      ],
      link: [
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Fira+Code:700|Roboto:400,400i,700,700i|Noto+Sans+JP:400,700&display=swap&subset=japanese' },
        { rel: 'favicon', href: 'favicon.ico' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'manifest', href: '/site.webmanifest' },
      ],
      // noscript: [
      //   { innerHTML: 'This website requires JavaScript.' },
      // ],
    }
    /* eslint-enable max-len, object-curly-newline */
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    // locale() { return this.$i18n.locale },
    showFooter() {
      const pathParts = this.$route.path.split('/')
      const currentPath = (pathParts.length ? pathParts[pathParts.length - 1] : null)
      return currentPath !== 'signup'
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch('loadPages')
    await this.$store.dispatch('loadStories')
  },
  beforeMount() {
    const lang = navigator.language || navigator.userLanguage
    const langPart = lang.split('-')[0]
    if (langPart !== 'en') {
      this.$router.push(`/${langPart}`)
    }
    if (!this.pages || !this.pages.length) {
      this.$store.dispatch('loadPages')
    }
    if (!this.stories || !this.stories[0].name || this.stories[0].name === '') {
      this.$store.dispatch('loadStories')
    }
  },
}
</script>

<style lang="scss">
@import 'normalize-scss';
@include normalize();

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0;
  display: table;
}

body {
  background-color: $body-bg;
  color: $text-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-size-weight;
  -webkit-font-smoothing: antialiased !important;

  &:lang(ja) {
    font-family: $font-family-japanese;
    font-size: 14px;
    line-height: 1.8;
  }
}

a {
  color: $link-color;
  font-weight: $font-size-header-weight;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.primary-nav {
  li {
    a {
      color: $text-color;
      text-decoration: none;

      &:after {
        content: '';
        background-color: transparent;
        display: block;
        height: 2px;
        transition: all 0.2s ease-out;
        width: 100%;
      }

      &:hover:after,
      &.router-link-active:after {
        background-color: $text-color;
      }
    }
  }
}

p {
  margin: 10px 0;
  line-height: 1.5;

  &:lang(ja) {
    margin: 15px 0;
    line-height: 1.8;
  }
}

.content {
  h4:lang(ja) {
    font-size: 16px;

    @include respond-above(md) {
      font-size: 20px;
    }
  }

  p:lang(ja) {
    margin: 8px 0 24px;

    @include respond-above(md) {
      margin-bottom: 32px;
      font-size: 16px;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-monospace;
  font-weight: $font-size-header-weight;
  line-height: 1.2;
  margin: 0;

  &:lang(ja) {
    font-family: $font-family-japanese;
    font-weight: 700;
    line-height: 1.6;
  }
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  margin: 20px 0 5px;

  @include respond-above(md) {
    font-size: 32px;
    margin: 20px 0;
    letter-spacing: 1px;
  }

  @include respond-above(lg) {
    margin: 20px 0 40px;
  }

  &:lang(ja) {
    font-size: 20px;

    @include respond-above(md) {
      font-size: 32px;
    }
  }
}

h4 {
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 1.4;

  @include respond-above(lg) {
    font-size: 22px;
    letter-spacing: 1px;
  }

  &:lang(ja) {
    font-size: 18px;
    line-height: 1.6;

    @include respond-above(lg) {
      font-size: 22px;
    }
  }
}

strong {
  font-weight: $font-size-header-weight;
}

input, select, textarea {
  font-family: $font-family-sans-serif;
  font-weight: $font-size-weight;

  &:lang(ja) {
    font-family: $font-family-japanese;
    font-weight: $font-size-weight;
  }
}

.button {
  background-color: $btn-default-bg;
  border: 0;
  color: $btn-default-text-color;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-monospace;
  font-size: $btn-default-font-size;
  font-weight: $font-size-header-weight;
  height: 56px;
  letter-spacing: 0.4px;
  line-height: 56px;
  min-width: 240px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s ease-out;
  width: 100%;

  &:hover {
    background-color: $btn-default-bg-hover;
    text-decoration: none;
  }

  &:lang(ja) {
    font-family: $font-family-japanese;
    font-size: 18px;
    font-weight: 700;
  }

  &.secondary {
    background-color: $plate-bg;
    color: $text-color;
    display: block;
    margin: 8px auto 0;

    @include respond-above(md) {
      max-width: 320px;
    }

    &:hover {
      background-color: rgba(24, 108, 210, 0.6);
    }
  }
}

.placeholder {
  background-color: $placeholder-color;
  display: block;
}

main {
  padding: 60px 0;
  min-height: 100%;

  @include respond-above(lg) {
    padding: 60px 0 90px;
  }
}

section {
  padding: 20px 16px;

  @include respond-above(lg) {
    padding: 65px 16px;
  }
}

.work,
.workers {
  margin: 0 auto;
  max-width: $article-width;
  padding: 0;
}

.form {
  background-color: $text-color;
  color: $btn-default-text-color;
  line-height: 1.15;
  padding: 16px;
  position: relative;

  @include respond-above(md) {
    padding: 60px 65px;
  }

  .form-group {
    @include clearfix();

    margin: 0 0 20px;
    vertical-align: top;

    label,
    input,
    textarea,
    .select-container {
      display: block;
      float: left;
      font-size: 16px;
      width: 100%;
    }

    label {
      padding: 10px 0;

      @media (min-width: $article-width) {
        width: 30%;
      }

      span {
        color: #9e9e9e;
        font-size: 14px;
      }
    }

    input,
    textarea,
    .select-container {
      background-color: #f1f1f1;
      border: 0;
      padding: 0 10px;
      line-height: 38px;

      @media (min-width: $article-width) {
        width: 70%;
      }
    }

    textarea {
      font-size: 14px;
      line-height: 1.4;
      height: 178px;
      padding: 12px;

      &:lang(ja) {
        line-height: 1.8;
      }
    }

    input[type=file] {
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 0.1px;
      z-index: -1;
    }

    .file-button {
      background-color: #000;
      color: #fff;
      cursor: pointer;
      font-family: $font-family-monospace;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      text-align: center;
      transition: background-color 0.2s ease-out;
      width: 100%;

      @media (min-width: $article-width) {
        margin-right: 10px;
        width: auto;
      }

      &:hover {
        background-color: #333;
      }

      &:lang(ja) {
        font-family: $font-family-japanese;
        font-weight: 700;
      }
    }

    .file-info {
      background-color: #f1f1f1;
      display: inline-block;
      font-size: 14px;
      padding: 0 10px 0 5px;
      line-height: 40px;
      height: 40px;
      width: 100%;

      @media (min-width: $article-width) {
        width: auto;
      }

      button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        display: inline-block;
        position: relative;
        height: 24px;
        text-indent: -99999em;
        width: 24px;

        &:before,
        &:after {
          background-color: #282828;
          content: '';
          height: 2px;
          top: 50%;
          left: 50%;
          position: absolute;
          width: 16px;
        }

        &:before {
          transform: translateX(-50%) rotate(45deg);
        }

        &:after {
          transform: translateX(-50%) rotate(-45deg);
        }
      }

      span {
        background: transparent url('./assets/icon-file.svg') no-repeat center left;
        background-size: 24px 24px;
        display: inline-block;
        line-height: 40px;
        padding-left: 25px;
      }
    }

    .select-container {
      color: #b4b4b4;
      height: 38px;
      line-height: 1.15;
      padding: 10px;
      position: relative;

      &:before {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #000;
        content: '\A';
        margin-top: -3px;
        position: absolute;
        right: 15px;
        top: 50%;
      }
    }

    select {
      appearance: none;
      background-color: transparent;
      border: 0;
      bottom: 0;
      height: 38px;
      left: 0;
      line-height: 38px;
      padding: 0 10px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 10;

      // &::-ms-expand {
      //   display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
      // }
    }

    button {
      @media (min-width: $article-width) {
        display: block;
        margin: 0 auto;
        width: 320px;
      }
    }

    .error-msg {
      color: #ff471e;
      display: block;
      float: left;
      font-size: 14px;
      padding: 5px 0;
      width: 100%;

      @media (min-width: $article-width) {
        float: right;
        width: 70%;
      }
    }

    &.form-error {
      input,
      textarea,
      .select-container {
        background-color: #FFEDE9;
      }
    }
  }

  .form-group-name {
    input {
      width: calc(50% - 5px);

      @media (min-width: $article-width) {
        width: calc(35% - 5px);
      }
    }

    #firstName {
      float: left;
      margin-right: 10px;

      &:lang(ja) {
        float: right;
        margin-right: 0;
      }
    }

    #lastName {
      float: right;

      &:lang(ja) {
        float: left;
        margin-right: 10px;
      }
    }
  }

  .form-group-policy {
    text-align: center;
  }

  &:before,
  &:after {
    background: #163161 url('./assets/form-bl.svg') no-repeat center center;
    background-size: 14px 14px;
    content: ' ';
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:after {
    background-image: url('./assets/form-br.svg');
    left: auto;
    right: 0;
  }
}

.fade-enter-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave,
.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

::placeholder {
  color: #b4b4b4;
  opacity: 1;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #b4b4b4;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #b4b4b4;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #b4b4b4;
}
:-moz-placeholder { /* Firefox 18- */
  color: #b4b4b4;
}
</style>
