<template>
  <header ref="header" :class="{ active: visibleMenu, sticky: stickyHeader }">
    <div class="header-inner">
      <div class="logo">
        <LocalizedLink to="/"><h1>Career Craft</h1></LocalizedLink>
      </div>
      <div class="navigation">
        <button class="menu-trigger" @click="toggleMenu"><span>{{ $t('menu') }}</span></button>
        <div class="menu">
          <nav class="primary-nav" aria-label="primary">
            <ul>
              <li>
                <LocalizedLink to="/process">{{ processTitle }}</LocalizedLink>
              </li>
              <li v-if="locale === 'ja'">
                <LocalizedLink to="/stories">{{ storiesTitle }}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/faq">{{ faqTitle }}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/about">{{ aboutTitle }}</LocalizedLink>
              </li>
            </ul>
          </nav>
          <LocalizedLink
            to="/signup"
            class="button"
            v-if="showSignup"
          >
            {{ $t('signup') }}
          </LocalizedLink>
          <SocialMedia />
          <div class="language-nav">
            <ul>
              <li><LanguageSwitch to="ja">JA</LanguageSwitch></li>
              <li><LanguageSwitch to="en">EN</LanguageSwitch></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<i18n>
{
  "en": {
    "menu": "Menu",
    "signup": "Sign up"
  },
  "ja": {
    "menu": "メニュー",
    "signup": "相談する"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import LanguageSwitch from './LanguageSwitch.vue'
import LocalizedLink from './LocalizedLink.vue'
import SocialMedia from './SocialMedia.vue'

export default {
  name: 'Header',
  components: {
    LanguageSwitch,
    LocalizedLink,
    SocialMedia,
  },
  computed: {
    locale() { return this.$i18n.locale },
    showSignup() {
      const pathParts = this.$route.path.split('/')
      const currentPath = (pathParts.length ? pathParts[pathParts.length - 1] : null)
      return currentPath !== 'signup'
    },
    processTitle() {
      const page = this.getPageBySlug('process') || {}
      if (this.locale !== 'en') {
        return page[`title_${this.locale}`]
      }
      return page.title
    },
    storiesTitle() {
      const page = this.getPageBySlug('stories') || {}
      if (this.locale !== 'en') {
        return page[`title_${this.locale}`]
      }
      return page.title
    },
    faqTitle() {
      const page = this.getPageBySlug('faq') || {}
      if (this.locale !== 'en') {
        return page[`title_${this.locale}`]
      }
      return page.title
    },
    aboutTitle() {
      const page = this.getPageBySlug('about') || {}
      if (this.locale !== 'en') {
        return page[`title_${this.locale}`]
      }
      return page.title
    },
    ...mapGetters(['getPageBySlug']),
  },
  data() {
    return {
      visibleMenu: false,
      stickyHeader: false,
    }
  },
  watch: {
    $route() {
      this.visibleMenu = false
      this.$refs.header.removeAttribute('style')
      this.setHeaderBg()
    },
  },
  methods: {
    toggleMenu() {
      this.visibleMenu = !this.visibleMenu
      this.$refs.header.removeAttribute('style')
      this.setHeaderBg()
    },
    handleScroll() {
      this.setHeaderBg()
      this.stickyHeader = window.scrollY >= 15
    },
    handleResize() {
      if (window.innerWidth > 1200) {
        this.visibleMenu = false
      }
      this.setHeaderBg()
    },
    setHeaderBg() {
      if (this.$refs.header) {
        const alpha = Math.round((window.scrollY / this.$refs.header.clientHeight) * 100) / 100
        if (this.visibleMenu || alpha >= 1) {
          this.$refs.header.setAttribute('style', 'background-color: #186cd2;')
        } else {
          this.$refs.header.setAttribute(
            'style',
            `background-color: rgba(24, 108, 210, ${alpha});`,
          )
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
$logo-height: 26px;
$logo-height-large: 32px;

header {
  height: 62px;
  padding: 18px 16px;
  position: fixed;
  width: 100%;
  z-index: 100;

  @include respond-above(md) {
    height: 72px;
    margin: 15px 0;
    padding: 8px 16px;
    position: absolute;
  }

  .header-inner {
    margin: 0 auto;
    max-width: 1404px;
    width: 100%;

    @include respond-above(md) {
      height: 56px;
      position: relative;
    }
  }

  .logo {
    display: inline-block;
    width: auto;
    z-index: 200;

    @include respond-above(md) {
      left: 0;
      position: absolute;
      top: 12px;
    }

    a {
      h1 {
        background: transparent url('../assets/logo-careercraft.png') no-repeat 0 0;
        background-size: auto $logo-height;
        height: $logo-height;
        overflow: hidden;
        text-indent: -9999em;
        width: 206px;

        @include respond-above(md) {
          background-image: url('../assets/logo-careercraft-square.png');
          background-size: auto 38px;
          height: 38px;
          width: 104px;
        }

        @include respond-above(lg) {
          background-image: url('../assets/logo-careercraft.png');
          background-size: auto $logo-height-large;
          height: $logo-height-large;
          width: 252px;
        }
      }
    }
  }

  .navigation {
    text-align: center;

    @include respond-above(md) {
      position: absolute;
      top: 0;
      right: 0;
      height: 56px;
      font-size: 18px;
      padding-left: 116px;
      text-align: left;
      width: 100%;
    }

    @include respond-above(lg) {
      padding-left: 272px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-family: $font-family-monospace;
        font-size: $font-size-h4;
        font-weight: $font-size-header-weight;

        &:lang(ja) {
          font-family: $font-family-japanese;
          font-size: 16px;
        }
      }
    }

    .menu-trigger {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 44px;
      position: absolute;
      top: 10px;
      right: 10px;
      text-indent: -9999em;
      width: 44px;

      @include respond-above(md) {
        display: none;
      }

      span {
        background-color: transparent;
        bottom: 50%;
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.2s ease-out;
        width: 24px;

        &:before,
        &:after {
          background-color: #fff;
          content: '';
          height: 100%;
          left: 50%;
          position: absolute;
          transition: all 0.2s ease-out;
          width: 100%;
        }

        &:before {
          transform: translate(-50%, 3px);
        }

        &:after {
          transform: translate(-50%, -3px);
        }
      }
    }

    .primary-nav {
      @include respond-above(md) {
        display: inline-block;
        padding: 8px 0;
      }

      ul {
        li {
          line-height: 1.15;
          padding: 4px 0;

          @include respond-above(md) {
            display: inline;
            padding: 4px 2px;
          }

          a {
            display: inline-block;
            padding: 10px;
          }
        }
      }
    }

    .menu {
      background-color: $plate-bg;
      display: none;
      left: 0;
      padding: 5px 16px;
      position: fixed;
      right: 0;
      top: 62px;
      width: 100%;

      @include respond-above(md) {
        background-color: transparent;
        display: block;
        padding: 0;
        position: relative;
        top: auto;
        right: auto;
        left: auto;
      }

      .button {
        margin: 20px 0 45px;

        @include respond-above(md) {
          max-width: 320px;
        }

        @include respond-above(md) {
          margin: 0;
          float: right;
          min-width: auto;
          width: 200px;
        }
      }
    }

    .language-nav {
      margin: 10px 0 28px;

      @include respond-above(md) {
        display: inline-block;
        float: right;
        margin: 0;
        padding: 8px 20px 8px 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          font-family: $font-family-monospace;
          font-size: $font-size-base;
          line-height: 1.15;
          padding: 2px 0;

          a {
            color: $text-color;
            display: inline-block;
            opacity: 0.5;
            padding: 10px;
            text-decoration: none;
            transition: opacity 0.2s ease-out;

            &:hover,
            &.router-link-exact-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.active {
    background-color: $plate-bg;

    .menu-trigger {
      span {
        &:before {
          transform: translateX(-50%) rotate(45deg);
        }

        &:after {
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }

    .menu {
      display: block;
    }
  }

  &.sticky {
    margin: 0;
    position: fixed;
  }
}
</style>
