<template>
  <main class="stories">
    <Article :content="content" />
    <div class="story-list-wrapper">
      <StoryList :stories="stories" />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'
import StoryList from '@/components/StoryList.vue'

export default {
  name: 'Stories',
  metaInfo: {
    title: 'Stories',
    meta: [
      { property: 'og:title', content: 'Stories | Career Craft' },
    ],
  },
  components: { Article, StoryList },
  computed: {
    locale() { return this.$i18n.locale },
    currentPage() { return this.getPageBySlug('stories') || {} },
    content() {
      if (this.locale !== 'en') {
        return this.currentPage[`content_${this.locale}`]
      }
      return this.currentPage.content
    },
    info() {
      const meta = this.currentPage.meta ? this.currentPage.meta[this.locale] : {}
      if (meta) {
        return meta.info
      }
      return {}
    },
    stories() {
      const stories = this.getStories()
      if (stories.length) {
        return stories
      }
      return [
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
      ]
    },
    ...mapGetters(['getPageBySlug', 'getStories']),
  },
}
</script>

<style lang="scss" scoped>
.stories {
  .story-list-wrapper {
    padding: 0 16px;
  }
}
</style>
