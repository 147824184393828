<template>
  <main class="signup-thanks">
    <Article :content="content" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'

export default {
  name: 'SignupThanks',
  metaInfo: {
    title: 'Thank you',
    meta: [
      { property: 'og:title', content: 'Thank you | Career Craft' },
    ],
  },
  components: { Article },
  computed: {
    locale() { return this.$i18n.locale },
    content() {
      const currentPage = this.getPageBySlug('signup-thanks') || {}
      if (this.locale !== 'en') {
        return currentPage[`content_${this.locale}`] || ''
      }
      return currentPage.content || ''
    },
    ...mapGetters(['getPageBySlug']),
  },
}
</script>

<style lang="scss" scoped>
.signup-thanks {
  article {
    &::v-deep {
      h1:before {
        background: transparent url('../assets/icon-mail.svg') no-repeat center center;
        content: ' ';
        display: block;
        height: 40px;
        margin: 0 auto 25px;
        width: 60px;

        @include respond-above(md) {
          margin-bottom: 45px;
        }
      }
    }
  }
}
</style>
