<template>
  <main class="story">
    <article>
      <div class="story-inner">
        <h2>{{ currentStory.header }}</h2>
        <div class="profile">
          <img v-if="currentStory.image && currentStory.image.url" :src="currentStory.image.url">
          <div class="name">
            <h4>{{ currentStory.name }}さん</h4>
            <p>{{ currentStory.personal_info }}</p>
          </div>
          <div class="jobs">
            <p>{{ currentStory.previous_job }}</p>
            <p>{{ currentStory.current_job }}</p>
          </div>
        </div>
        <div class="summary">
          <p>{{ currentStory.summary }}</p>
        </div>
        <div class="content" v-html="htmlContent" />
        <div class="comment">
          <h4>担当コンサルタントからのコメント</h4>
          <p>{{ currentStory.consultant_comment }}</p>
          <div class="consultant">
            <img
              v-if="currentStory.consultant.image && currentStory.consultant.image.url"
              :src="currentStory.consultant.image.url"
            >
            <h4>{{ currentStory.consultant.name_ja }}</h4>
            <p>{{ currentStory.consultant.title_ja }}</p>
          </div>
        </div>
      </div>
    </article>
    <div class="button-wrapper">
      <LocalizedLink to="/stories" class="button secondary">転職成功事例一覧へ戻る</LocalizedLink>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import marked from 'marked'
import LocalizedLink from '@/components/LocalizedLink.vue'

export default {
  name: 'Story',
  metaInfo: {
    title: 'Story',
    meta: [
      { property: 'og:title', content: 'Story | Career Craft' },
    ],
  },
  components: { LocalizedLink },
  computed: {
    locale() { return this.$i18n.locale },
    currentStory() {
      const storyId = parseInt(this.$route.params.id, 10)
      return this.getStoryById(storyId) || {}
    },
    htmlContent() {
      if (this.currentStory && this.currentStory.content) {
        return marked(this.currentStory.content)
      }
      return ''
    },
    ...mapGetters(['getStoryById']),
  },
}
</script>

<style lang="scss" scoped>
.story {
  @include respond-above(xl) {
    padding-top: 126px;
  }

  article {
    background-color: $plate-bg-dark;
    border-radius: 25px;
    margin: 0 auto;
    max-width: $content-width;
    padding: 24px 16px 30px;
    position: relative;

    @include respond-above(sm) {
      padding: 48px 16px;
    }

    @include respond-above(md) {
      padding: 86px 16px;
    }

    &:before,
    &:after {
      background-color: #1c4287;
      mask: url('../assets/form-tab-tl.svg') no-repeat 50% 50%;
      content: ' ';
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      mask: url('../assets/form-tab-tr.svg') no-repeat 50% 50%;
      left: auto;
      right: 0;
    }

    .story-inner {
      margin: 0 auto;
      max-width: 710px;
    }

    h2 {
      font-size: 22px;
      margin: 0 0 20px;
      text-align: center;

      @include respond-above(md) {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }

    .profile {
      padding-left: 88px;
      position: relative;

      @include respond-above(md) {
        padding-left: 156px;
      }

      img {
        height: 74px;
        position: absolute;
        width: 74px;
        top: 0;
        left: 0;

        @include respond-above(md) {
          height: 124px;
          width: 124px;
        }
      }

      .name {
        display: flex;
        flex-direction: column;
        height: 74px;
        justify-content: center;

        @include respond-above(md) {
          display: block;
          height: auto;
        }

        h4 {
          font-size: 20px;
          line-height: 1;
          margin: 0 0 5px;
        }

        p {
          margin: 0;

          @include respond-above(md) {
            font-size: 16px;
          }
        }
      }

      .jobs {
        @include clearfix;

        margin-left: -88px;
        margin-top: 16px;
        position: relative;

        @include respond-above(md) {
          margin-left: 0;
        }

        &:before {
          background: url('../assets/story-arrow.png') no-repeat center center;
          background-size: 24px auto;
          content: ' ';
          height: 24px;
          left: 50%;
          position: absolute;
          top: 0;
          width: 24px;
          margin-left: -12px;
        }

        p {
          float: left;
          font-size: 12px;
          font-weight: 700;
          margin: 0 20px 0 0;
          overflow: hidden;
          padding: 00;
          width: calc(50% - 20px);

          @include respond-above(md) {
            font-size: 16px;
            max-height: 58px;
          }

          &:last-child {
            margin-left: 20px;
            margin-right: 0;
          }

          &.placeholder {
            height: 24px;
          }
        }
      }
    }

    .summary {
      background-color: $plate-bg;
      padding: 16px;
      margin: 24px 0;

      @include respond-above(md) {
        margin: 32px 0;
        padding: 24px;
      }

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.6;
        margin: 0;

        @include respond-above(md) {
          font-size: 18px;
        }
      }
    }

    .comment {
      background-color: $body-bg;
      padding: 16px;

      @include respond-above(md) {
        padding: 24px;
      }

      &:before,
      &:after {
        background-color: #1c4287;
        bottom: 0;
        content: ' ';
        height: 25px;
        left: 0;
        mask: url('../assets/form-tab-tl.svg') no-repeat 50% 50%;
        position: absolute;
        transform: rotate(-90deg);
        width: 25px;
      }

      &:after {
        mask: url('../assets/form-tab-tr.svg') no-repeat 50% 50%;
        left: auto;
        right: 0;
        transform: rotate(90deg);
      }

      h4:lang(ja) {
        font-size: 16px;

        @include respond-above(md) {
          font-size: 20px;
        }
      }

      p:lang(ja) {
        margin: 10px 0;

        @include respond-above(md) {
          font-size: 16px;
          margin-bottom: 16px;
        }
      }

      .consultant {
        display: flex;
        flex-direction: column;
        height: 74px;
        justify-content: center;
        padding-left: 88px;
        position: relative;

        @include respond-above(md) {
          height: 80px;
          padding-left: 104px;
        }

        img {
          height: 74px;
          left: 0;
          position: absolute;
          top: 0;
          width: 74px;

          @include respond-above(md) {
            height: 80px;
            width: 80px;
          }
        }

        h4:lang(ja) {
          @include respond-above(md) {
            font-size: 18px;
          }
        }

        p:lang(ja) {
          font-size: 13px;
          margin: 0;
        }
      }
    }
  }

  .button-wrapper {
    padding: 48px 16px 0;

    @include respond-above(md) {
      padding-top: 80px;
    }

    .button {
      margin: 0 auto;
    }
  }
}
</style>
