<template>
  <section class="hero">
    <div class="hero-overlay">
      <div class="hero-inner">
        <div class="hero-content">
          <div class="hero-info">
            <h2>{{ hero.title }}</h2>
            <p>{{ hero.content }}</p>
            <LocalizedLink to="/signup" class="button">{{ $t('signup') }}</LocalizedLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n>
{
  "en": {
    "signup": "Sign up"
  },
  "ja": {
    "signup": "Career Craftに相談する"
  }
}
</i18n>

<script>
import LocalizedLink from './LocalizedLink.vue'

export default {
  name: 'Hero',
  components: {
    LocalizedLink,
  },
  props: {
    hero: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.hero {
  background: url('../assets/bg-careercraft.jpg') no-repeat center center;
  background-size: cover;
  padding: 0;
  width: 100%;

  &:lang(ja) {
    background: url('../assets/bg-careercraft-ja.jpg') no-repeat center center;
    background-size: cover;
  }

  .hero-overlay {
    background-color: rgba(0, 57, 112, 0.2);
    padding: 68px 16px 40px;

    @include respond-above(md) {
      display: table;
      font-size: 20px;
      font-weight: $font-size-header-weight;
      padding: 16px;
      height: 650px;
      width: 100%;
    }

    &:lang(ja) {
      background-color: rgba(0, 51, 98, 0.4);
    }
  }

  .hero-inner {
    @include respond-above(md) {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .hero-content {
    margin: 0 auto;
    max-width: $content-width;
  }

  .hero-info {
    max-width: $article-width;

    &:lang(ja) {
      max-width: 600px;
    }
  }

  h2 {
    background: url('../assets/hero-flag-japan.svg') no-repeat right bottom 4px;
    background-size: 34px auto;
    display: inline;
    font-size: $font-size-h3;
    line-height: 1.4;
    margin: 0;
    padding-right: 44px;

    @include respond-above(md) {
      background-position: right bottom 14px;
      background-size: 46px auto;
      font-size: 50px;
      line-height: 1.2;
      padding-right: 70px;
      margin: 0 0 20px;
    }

    &:lang(ja) {
      background: none;
      font-family: $font-family-monospace;
      line-height: 1.2;
    }
  }

  p {
    @include respond-above(md) {
      margin: 20px 0;
    }

    &:lang(ja) {
      font-size: 16px;
    }
  }

  .button {
    margin-top: 12px;

    @include respond-above(md) {
      margin-top: 25px;
      width: 320px;
    }

    &:lang(ja) {
      text-transform: unset;
    }
  }
}
</style>
