<template>
  <div class="form">
    <form id="form-work" @submit.prevent="submit" action="/sendmail" method="post">
      <div class="form-group form-group-name" :class="{'form-error': errors.name}">
        <label for="firstName">{{ $t('name') }}</label>
        <input
          type="text"
          id="firstName"
          v-model="form.firstName"
          :placeholder="$t('firstname-ph')"
        >
        <input type="text" id="lastName" v-model="form.lastName" :placeholder="$t('lastname-ph')">
        <span class="error-msg" v-if="errors.name">{{ $t('name-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.company}">
        <label for="company">{{ $t('company') }}</label>
        <input type="text" id="company" v-model="form.company" :placeholder="$t('company-ph')">
        <span class="error-msg" v-if="errors.company">{{ $t('company-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.role}">
        <label for="role">{{ $t('role') }}</label>
        <div class="select-container">
          <span>{{ !this.form.role ? $t('role-ph') : '' }}</span>
          <select id="role" v-model="form.role">
            <option disabled>{{ $t('role-ph') }}</option>
            <optgroup v-for="(groupRoles, group) in roles" :label="group" :key="group">
              <option v-for="(role, index) in groupRoles" :key="index" :value="role.name">
                {{ role.name }}
              </option>
            </optgroup>
          </select>
        </div>
        <span class="error-msg" v-if="errors.role">{{ $t('role-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.country}" v-if="locale === 'en'">
        <label for="country">{{ $t('country') }}</label>
        <div class="select-container">
          <span>{{ !this.form.country ? $t('country-ph') : '' }}</span>
          <select id="country" v-model="form.country">
            <option disabled value="">{{ $t('country-ph') }}</option>
            <option v-for="country in countries" :key="country.code">{{ country.name }}</option>
          </select>
        </div>
        <span class="error-msg" v-if="errors.country">{{ $t('country-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.japaneseLevel}" v-if="locale === 'en'">
        <label for="japanese-level">{{ $t('japanese-level') }}</label>
        <div class="select-container">
          <span>{{ !this.form.japaneseLevel ? $t('japanese-level-ph') : '' }}</span>
          <select id="japanese-level" v-model="form.japaneseLevel">
            <option disabled value="">{{ $t('japanese-level-ph') }}</option>
            <option>None</option>
            <option>Elementary proficiency</option>
            <option>Limited working proficiency</option>
            <option>Professional working proficiency</option>
            <option>Full professional proficiency</option>
            <option>Native or bilingual proficiency</option>
          </select>
        </div>
        <span class="error-msg" v-if="errors.japaneseLevel">{{ $t('japanese-level-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.englishLevel}" v-if="locale === 'ja'">
        <label for="english-level">{{ $t('english-level') }}</label>
        <div class="select-container">
          <span>{{ !this.form.englishLevel ? $t('english-level-ph') : '' }}</span>
          <select id="english-level" v-model="form.englishLevel">
            <option disabled value="">{{ $t('english-level-ph') }}</option>
            <option>なし</option>
            <option>挨拶レベル</option>
            <option>日常会話レベル</option>
            <option>ビジネス初級</option>
            <option>ビジネス上級</option>
            <option>母国語またはバイリンガル</option>
          </select>
        </div>
        <span class="error-msg" v-if="errors.englishLevel">{{ $t('english-level-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.email}">
        <label for="email">{{ $t('email') }}</label>
        <input type="text" id="email" v-model="form.email" :placeholder="$t('email-ph')">
        <span class="error-msg" v-if="errors.email">{{ $t('email-error') }}</span>
      </div>
      <div class="form-group">
        <label for="phone">{{ $t('phone') }} <span>{{ $t('optional') }}</span></label>
        <input type="text" id="phone" v-model="form.phone" :placeholder="$t('phone-ph')">
      </div>
      <div class="form-group">
        <label for="linkedin">{{ $t('linkedin') }} <span>{{ $t('optional') }}</span></label>
        <input type="text" id="linkedin" v-model="form.linkedin" :placeholder="$t('linkedin-ph')">
      </div>
      <div class="form-group">
        <label>{{ $t('resume') }} <span>{{ $t('optional') }}</span></label>
        <input type="file" id="resume" @change="fileChange($event)">
        <label for="resume" class="file-button">{{ $t('resume-button') }}</label>
        <div class="file-info" v-if="form.file">
          <button @click.prevent="removeFile()">Remove</button>
          <span>{{ form.file.name }}</span>
        </div>
        <span class="error-msg" v-if="errors.resume">{{ $t('resume-error') }}</span>
      </div>
      <div class="form-group">
        <label for="message">{{ $t('message') }} <span>{{ $t('optional') }}</span></label>
        <textarea id="message" v-model="form.message" rows="6" />
      </div>
      <div class="form-group form-group-policy">
        <i18n path="privacy-text" tag="p" for="privacy-policy">
          <LocalizedLink to="/privacy-policy">{{ $t('privacy-policy') }}</LocalizedLink>
        </i18n>
      </div>
      <div class="form-group">
        <button type="submit" class="button">{{ $t('signup') }}</button>
      </div>
    </form>
  </div>
</template>

<i18n>
{
  "en": {
    "name": "Name",
    "name-error": "Name is required",
    "firstname-ph": "First name",
    "lastname-ph": "Last name",
    "company": "Current Company",
    "company-error": "Company is required",
    "company-ph": "Company, Inc.",
    "role": "Role",
    "role-error": "Role is required",
    "role-ph": "Select an option",
    "country": "Country of residence",
    "country-error": "Country of residence is required",
    "country-ph": "Select an option",
    "japanese-level": "Japanese Proficiency",
    "japanese-level-error": "Japanese Proficiency is required",
    "japanese-level-ph": "Select an option",
    "english-level": "English skills",
    "english-level-error": "English skills is required",
    "english-level-ph": "Select an option",
    "email": "Email",
    "email-error": "A valid email address is required",
    "email-ph": "name@email.com",
    "phone": "Phone number",
    "phone-ph": "+1 111-111-1111",
    "linkedin": "Linkedin",
    "linkedin-ph": "https://www.linkedin.com/in/name",
    "resume": "Resume attachment",
    "resume-button": "Attach PDF",
    "resume-error": "Supported filetype is PDF",
    "message": "Free text",
    "optional": "(Optional)",
    "default-select": "Select an option",
    "privacy-text": "Submitting this registration form means you’re okay with our {0}.",
    "privacy-policy": "Privacy Policy",
    "signup": "Sign up"
  },
  "ja": {
    "name": "氏名",
    "name-error": "名前が必要です",
    "firstname-ph": "名",
    "lastname-ph": "姓",
    "company": "在籍中",
    "company-error": "企業名が必要です",
    "company-ph": "株式会社◯◯",
    "role": "職種",
    "role-error": "職種が必要です",
    "role-ph": "職種をお選びください",
    "country": "Country of residence",
    "country-error": "Country of residence is required",
    "country-ph": "Select an option",
    "japanese-level": "Japanese Proficiency",
    "japanese-level-error": "Japanese Proficiency is required",
    "japanese-level-ph": "Select an option",
    "english-level": "英語力",
    "english-level-error": "英語力が必要です",
    "english-level-ph": "英語力をお選びください",
    "email": "メールアドレス",
    "email-error": "有効なメールアドレスが必要です",
    "email-ph": "name@email.com",
    "phone": "電話番号",
    "phone-ph": "090-123-4567",
    "linkedin": "Linkedin",
    "linkedin-ph": "https://www.linkedin.com/in/name",
    "resume": "履歴書・職務経歴書",
    "resume-button": "PDFを添付",
    "resume-error": "サポートされているファイル形式はPDFです",
    "message": "自由記述欄",
    "optional": "(任意)",
    "privacy-text": "登録フォームを送信した時点で、Career Craftの{0}に同意したものとみなされます。",
    "privacy-policy": "プライバシーポリシー",
    "signup": "送信"
  }
}
</i18n>

<script>
import LocalizedLink from './LocalizedLink.vue'
import config from '../helpers/config'
import countries from '../helpers/countries.json'

export default {
  name: 'FormWork',
  components: { LocalizedLink },
  props: {
    roles: Object,
  },
  computed: {
    locale() { return this.$i18n.locale },
  },
  data() {
    return {
      countries,
      errors: {
        name: false,
        company: false,
        role: false,
        country: false,
        englishLevel: false,
        japaneseLevel: false,
        email: false,
        resume: false,
      },
      form: {
        type: 'Candidate',
        file: null,
        firstName: null,
        lastName: null,
        company: null,
        role: null,
        country: null,
        japaneseLevel: null,
        englishLevel: null,
        email: null,
        phone: null,
        linkedin: null,
        resume: null,
        message: null,
      },
    }
  },
  methods: {
    fileChange(event) {
      const file = event.target.files[0]
      if (file.type === 'application/pdf') {
        this.form.file = file
        this.errors.resume = false
      } else {
        this.errors.resume = true
      }
    },
    removeFile() {
      this.form.file = null
      this.errors.resume = null
    },
    validate() {
      this.errors.name = (!this.form.firstName || !this.form.lastName)
      this.errors.company = !this.form.company
      this.errors.role = !this.form.role
      this.errors.email = (!this.form.email
        || !config.validate.email.test(String(this.form.email).toLowerCase()))
      if (this.locale === 'ja') {
        // japanese form
        this.errors.englishLevel = !this.form.englishLevel
        return (
          !this.errors.name && !this.errors.company && !this.errors.role
          && !this.errors.englishLevel && !this.errors.email
        )
      }
      // english form
      this.errors.country = !this.form.country
      this.errors.japaneseLevel = !this.form.japaneseLevel
      return (
        !this.errors.name && !this.errors.company && !this.errors.role && !this.errors.country
        && !this.errors.japaneseLevel && !this.errors.email
      )
    },
    submit() {
      if (this.validate()) {
        this.$store.dispatch('sendMail', { form: this.form, locale: this.locale }).then((res) => {
          if (res.data.sent) {
            this.$router.push(`${this.locale !== 'en' ? `/${this.locale}` : ''}/signup/thanks`)
          }
          // TODO: Handle error
          // throw new Error('Error')
        })
      }
    },
  },
}
</script>
