import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import i18n from '../i18n'
import About from '../views/About.vue'
import Faq from '../views/Faq.vue'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Privacy from '../views/Privacy.vue'
import Process from '../views/Process.vue'
import Signup from '../views/Signup.vue'
import SignupThanks from '../views/SignupThanks.vue'
import Stories from '../views/Stories.vue'
import Story from '../views/Story.vue'

Vue.use(Router)
Vue.use(Meta, {
  ssrAppId: 1,
  ssrAttribute: 'data-vue-meta-server-rendered',
  refreshOnceOnNavigation: true,
})

const createRouter = () => {
  const children = [
    {
      path: '',
      component: Home,
    },
    {
      path: 'process',
      // component: () => import(/* webpackChunkName: "process" */ '../views/Process.vue'),
      component: Process,
    },
    {
      path: 'stories',
      // component: () => import(/* webpackChunkName: "stories" */ '../views/Stories.vue'),
      component: Stories,
    },
    {
      path: 'stories/:id',
      // component: () => import(/* webpackChunkName: "stories" */ '../views/Story.vue'),
      component: Story,
    },
    {
      path: 'faq',
      // component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
      component: Faq,
    },
    {
      path: 'about',
      // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      component: About,
    },
    {
      path: 'privacy-policy',
      // component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
      component: Privacy,
    },
    {
      path: 'signup/thanks',
      component: SignupThanks,
    },
    {
      path: 'signup',
      // component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
      component: Signup,
    },
  ]

  const routes = [
    {
      path: '/',
      component: {
        render: h => h('router-view'),
      },
      children,
    },
    // {
    //   path: '/en',
    //   redirect: '/',
    // },
    {
      path: '/:lang(ja)',
      component: {
        render: h => h('router-view'),
      },
      children,
    },
    {
      path: '/*',
      redirect: '/',
    },
    {
      path: '*',
      component: NotFound,
    },
  ]

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
  })

  router.beforeEach((to, from, next) => {
    if (
      (to.path === '/stories' || to.params.id)
      && to.params.lang !== 'ja'
    ) {
      next('/')
    }
    i18n.locale = to.params.lang || 'en'
    next()
  })

  return router
}

// eslint-disable-next-line import/prefer-default-export
export { createRouter }
