<template>
  <section class="usp">
    <h2>{{ title }}</h2>
    <div v-if="features" class="features">
      <div v-for="feature in features" :key="feature.title">
        <img v-if="feature.icon" :src="feature.icon" alt="">
        <h4>{{ feature.title }}</h4>
        <p>{{ feature.content }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Usp',
  props: {
    title: String,
    features: Array,
  },
}
</script>

<style lang="scss" scoped>
.usp {
  margin: 0 auto;
  max-width: $content-width;
  text-align: center;

  p {
    text-align: left;
  }

  .features {
    @include clearfix();

    padding: 10px 0 0;

    @include respond-above(lg) {
      margin: 60px -50px 0;
      padding: 0;
    }

    > div {
      margin: 0 auto;
      max-width: $article-width;
      padding: 10px 0 30px;
      width: 100%;

      @include respond-above(lg) {
        padding: 0 35px 30px 35px;
        float: left;
        width: 33.33%;
      }

      img {
        display: inline-block;
        margin: 0 0 10px;
        height: 36px;
        width: auto;

        @include respond-above(lg) {
          margin: 0 0 15px;
          height: 52px;
        }
      }

      h4 {
        @include respond-above(lg) {
          margin: 15px 0;
          height: 54px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
