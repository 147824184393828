<template>
  <div class="question" :class="{ visible: visible }">
    <h3 @click="toggleVisible" :class="{placeholder: !question.question}">
      {{ question.question }}
    </h3>
    <div class="answer" v-show="visible" v-html="htmlAnswer" />
  </div>
</template>

<script>
import marked from 'marked'

export default {
  name: 'Question',
  props: {
    question: Object,
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    htmlAnswer() {
      return marked(this.question.answer || '')
    },
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
  },
}
</script>

<style lang="scss" scoped>
.question {
  background-color: $plate-bg-dark;
  margin: 10px 0;
  padding: 16px;
  position: relative;

  @include respond-above(md) {
    margin: 15px 0;
    padding: 26px;
  }

  h3 {
    cursor: pointer;
    font-family: $font-family-sans-serif;
    font-size: 18px;
    font-weight: $font-size-header-weight;
    letter-spacing: 0.5px;
    line-height: 1.4;
    padding-right: 34px;

    @include respond-above(md) {
      font-size: 20px;
    }

    &:lang(ja) {
      font-family: $font-family-japanese;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.8;

      @include respond-above(md) {
        font-size: 18px;
      }
    }

    &:before,
    &:after {
      background-color: #fff;
      content: '';
      height: 2px;
      position: absolute;
      right: 16px;
      top: 28px;
      width: 16px;

      @include respond-above(md) {
        right: 26px;
        top: 38px;
      }
    }

    &:after {
      display: block;
      transform: rotate(90deg);
    }

    &.placeholder {
      background-color: $plate-bg-dark;
      height: 25px;
      width: 70%;
    }
  }

  &:first-child h3.placeholder {
    width: 90%;
  }

  &:last-child h3.placeholder {
    width: 80%;
  }

  &.visible {
    background-color: $plate-bg;

    h3:after {
      display: none;
    }
  }

  .answer {
    &::v-deep {
      p {
        @include respond-above(md) {
          margin: 20px 0 5px;
        }

        &:lang(ja) {
          @include respond-above(md) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
