<template>
  <section class="intro">
    <div class="intro-inner">
      <p>{{ content }}</p>
      <div class="clouds">
        <div class="cloud cloud-b cloud-1" />
        <div class="cloud cloud-b cloud-2" />
        <div class="cloud cloud-a cloud-3" />
        <div class="cloud cloud-a cloud-4" />
        <div class="cloud cloud-a cloud-5" />
        <div class="cloud cloud-b cloud-6" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    content: String,
  },
}
</script>

<style lang="scss" scoped>
.intro {
  background-color: $plate-bg;
  overflow: hidden;
  position: relative;

  @include respond-above(lg) {
    padding: 0 16px;
  }

  .intro-inner {
    background: url('../assets/illustration-intro.svg') no-repeat center bottom;
    background-size: auto 272px;
    margin: 0 auto;
    max-width: $content-width + 50px;
    padding: 0 0 300px;

    @include respond-above(lg) {
      background-position: right -50px center;
      background-size: auto 384px;
      min-height: 444px;
      padding: 90px 0;
    }

    p {
      font-family: $font-family-monospace;
      font-size: 19px;
      letter-spacing: 1px;
      line-height: 1.6;

      @include respond-above(lg) {
        font-size: 22px;
        letter-spacing: 0.5px;
        margin-left: 25px;
        width: $article-width;
      }

      &:lang(ja) {
        font-family: $font-family-japanese;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.6;

        @include respond-above(lg) {
          font-size: 22px;
          line-height: 1.8;
        }
      }
    }
  }

  .clouds {
    .cloud {
      position: absolute;
    }

    .cloud-a {
      background: transparent url('../assets/illustration-cloud-a.svg') no-repeat center center;
      background-size: 60px 56px;
      height: 60px;
      width: 56px;
    }

    .cloud-b {
      background: transparent url('../assets/illustration-cloud-b.svg') no-repeat center center;
      background-size: 84px 68px;
      height: 68px;
      width: 84px;
    }

    .cloud-1 {
      right: 5px;
      bottom: 220px;

      @include respond-above(lg) {
        right: -50px;
        bottom: 310px;
      }
    }

    .cloud-2 {
      left: -54px;
      bottom: 208px;

      @include respond-above(lg) {
        left: auto;
        right: 160px;
        bottom: 270px;
      }
    }

    .cloud-3 {
      left: calc(50% - 122px);
      bottom: 126px;

      @include respond-above(lg) {
        left: calc(50% + 225px);
        bottom: 168px;
      }
    }

    .cloud-4 {
      display: none;

      @include respond-above(lg) {
        display: block;
        right: 20px;
        bottom: 100px;
      }
    }

    .cloud-5 {
      display: none;

      @include respond-above(lg) {
        display: block;
        left: -40px;
        bottom: 300px;
      }

      @media (min-width: 1300px) {
        left: 20px;
      }
    }

    .cloud-6 {
      display: none;

      @include respond-above(lg) {
        display: block;
        left: 30px;
        bottom: 55px;
      }

      @media (min-width: 1300px) {
        left: 70px;
      }
    }
  }
}
</style>
