<template>
  <main class="about">
    <Article :content="content" />
    <Employees />
    <CompanyInfo :items="info" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'
import Employees from '@/components/Employees.vue'
import CompanyInfo from '@/components/CompanyInfo.vue'

export default {
  name: 'About',
  metaInfo: {
    title: 'About us',
    meta: [
      { property: 'og:title', content: 'About us | Career Craft' },
    ],
  },
  components: { Article, Employees, CompanyInfo },
  computed: {
    locale() { return this.$i18n.locale },
    currentPage() { return this.getPageBySlug('about') || {} },
    content() {
      if (this.locale !== 'en') {
        return this.currentPage[`content_${this.locale}`]
      }
      return this.currentPage.content
    },
    info() {
      const meta = this.currentPage.meta ? this.currentPage.meta[this.locale] : {}
      if (meta) {
        return meta.info
      }
      return {}
    },
    ...mapGetters(['getPageBySlug']),
  },
}
</script>
