import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'

Vue.use(Vuex)

const createStore = () => {
  const store = new Vuex.Store({
    state: {
      employees: [],
      faqs: [],
      pages: [],
      roles: [],
      stories: [],
    },
    getters: {
      getFaqsByLang: (state) => (lang) => state.faqs.filter(q => q.language === lang),
      getPageBySlug: (state) => (slug) => state.pages.find(page => page.slug === slug),
      getRoles: (state) => () => state.roles,
      getStories: (state) => () => state.stories,
      getStoryById: (state) => (id) => state.stories.find(story => story.id === id),
    },
    mutations: {
      SET_EMPLOYEES(state, employees) {
        state.employees = employees
      },
      SET_FAQS(state, faqs) {
        faqs.forEach((question) => {
          if (!state.faqs.find(q => q.id === question.id)) {
            state.faqs.push(question)
          }
        })
      },
      SET_PAGES(state, pages) {
        state.pages = pages
      },
      SET_ROLES(state, roles) {
        state.roles = roles
      },
      SET_STORIES(state, stories) {
        state.stories = stories
      },
    },
    actions: {
      async loadEmployees({ commit }) {
        await api.fetchEmployees().then(response => commit('SET_EMPLOYEES', response.data))
      },
      async loadFaqs({ commit }, payload) {
        await api.fetchFaqs(payload.lang).then(response => commit('SET_FAQS', response.data))
      },
      async loadPages({ commit }) {
        await api.fetchPages().then(response => commit('SET_PAGES', response.data))
      },
      async loadRoles({ commit }) {
        await api.fetchRoles().then(response => commit('SET_ROLES', response.data))
      },
      async loadStories({ commit }) {
        await api.fetchStories().then(response => commit('SET_STORIES', response.data))
      },
      async sendMail(context, payload) {
        return api.sendMail(payload.form, payload.locale)
      },
    },
    modules: {
    },
  })

  return store
}

// eslint-disable-next-line import/prefer-default-export
export { createStore }
