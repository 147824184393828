<template>
  <div class="employees">
    <div class="employees-inner">
      <h2>{{ $t('our-team') }}</h2>
      <ul>
        <li v-for="employee in localizedEmployees" :key="employee.id">
          <div class="picture">
            <img v-if="employee.image && employee.image.url" :src="employee.image.url">
          </div>
          <div class="info">
            <h3 :class="{placeholder: !employee.name}">{{ employee.name }}</h3>
            <h4 :class="{placeholder: !employee.title}">{{ employee.title }}</h4>
            <a
              :href="employee.linkedin"
              v-if="employee.linkedin"
              title="LinkedIn"
              class="linkedin"
            >
              LinkedIn
            </a>
          </div>
          <p :class="{placeholder: !employee.title}">{{ employee.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "our-team": "Our Team"
  },
  "ja": {
    "our-team": "弊社コンサルタント"
  }
}
</i18n>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Employees',
  computed: {
    apiUrl() { return process.env.VUE_APP_API_URL },
    locale() { return this.$i18n.locale },
    localizedEmployees() {
      if (this.employees.length) {
        if (this.locale !== 'en') {
          return this.employees.map((employee) => ({
            ...employee,
            name: employee.name_ja,
            title: employee.title_ja,
            description: employee.description_ja,
          }))
        }
        return this.employees
      }
      return [
        { name: '', title: '', description: '' },
        { name: '', title: '', description: '' },
        { name: '', title: '', description: '' },
      ]
    },
    ...mapState(['employees']),
  },
  async serverPrefetch() {
    await this.$store.dispatch('loadEmployees')
  },
  beforeMount() {
    if (!this.employees.length) {
      this.$store.dispatch('loadEmployees')
    }
  },
}
</script>

<style lang="scss" scoped>
.employees {
  background-color: $plate-bg;
  padding: 16px 16px 10px;
  margin-top: 40px;

  @include respond-above(lg) {
    margin-top: 90px;
    padding: 66px 16px;
  }

  .employees-inner {
    @include clearfix();

    margin: 0 auto;
    max-width: $content-width;
  }

  h2 {
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    @include respond-above(md) {
      margin: 0 -15px 0 -15px;
    }

    @include respond-above(lg) {
      margin: 0 -30px 0 -30px;
    }

    li {
      display: inline-block;
      margin: 20px 0;
      position: relative;
      vertical-align: top;
      width: auto;

      @include respond-above(md) {
        padding: 0 30px;
        width: 50%;
      }

      @include respond-above(lg) {
        width: 33.33%;
      }
    }
  }

  .picture {
    background-color: #d1d1d1;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;

    @include respond-above(md) {
      left: 30px;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  .info {
    margin-bottom: 15px;
    padding-left: 120px;
    position: relative;
    height: 100px;

    @include respond-above(lg) {
      margin-bottom: 25px;
      padding-left: 130px;
    }

    h3 {
      font-size: 18px;

      &.placeholder {
        height: 16px;
      }
    }

    h4 {
      font-size: 14px;
      letter-spacing: 0;
      margin: 5px 0 0;

      &:lang(ja) {
        font-family: $font-family-japanese;
        letter-spacing: 0.2px;
        line-height: 1.4;
      }

      &.placeholder {
        height: 14px;
      }
    }

    .linkedin {
      background: transparent url('../assets/icon-linkedin.png') no-repeat center center;
      background-size: 26px 26px;
      bottom: 0;
      display: block;
      height: 26px;
      left: 120px;
      overflow: hidden;
      position: absolute;
      text-indent: -9999em;
      width: 26px;

      @include respond-above(lg) {
        left: 130px;
      }
    }

    h3.placeholder {
      width: 130px;
    }

    h4.placeholder {
      margin: 10px 0 0;
      width: 70px;
    }
  }

  p.placeholder {
    height: 50px;
    width: 90%;
  }
}
</style>
