<template>
  <section class="award">
    <div class="award-inner" v-html="htmlContent" />
  </section>
</template>

<script>
import marked from 'marked'

export default {
  name: 'Award',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    htmlContent() { return marked(this.content) },
  },
}
</script>

<style lang="scss" scoped>
.award {
  background-color: $plate-bg-dark;
  margin: 0 auto;
  padding: 60px 16px 70px;
  position: relative;
  max-width: $content-width;

  @include respond-above(md) {
    padding: 75px 16px 115px;
  }

  &:before,
  &:after,
  .award-inner:before,
  .award-inner:after {
    border-left: 3px solid #fff;
    border-top: 3px solid #fff;
    content: '';
    height: 16px;
    left: 16px;
    position: absolute;
    top: 16px;
    width: 16px;
  }

  &:after {
    left: auto;
    right: 16px;
    top: 16px;
    transform: rotate(90deg);
  }

  .award-inner:before {
    bottom: 16px;
    top: auto;
    transform: rotate(-90deg);
  }

  .award-inner:after {
    bottom: 16px;
    left: auto;
    right: 16px;
    top: auto;
    transform: rotate(180deg);
  }

  .award-inner {
    margin: 0 auto;
    max-width: $article-width;
  }

  &::v-deep {
    text-align: center;

    h1 {
      background: url('../assets/icon-award.svg') no-repeat center left;
      background-size: 24px auto;
      display: inline-block;
      font-size: 28px;
      padding-left: 30px;
      line-height: 60px;
      height: 60px;

      @include respond-above(md) {
        background-size: 36px auto;
        font-size: 32px;
        padding-left: 50px;
      }

      &:lang(ja) {
        font-size: 20px;

        @include respond-above(md) {
          font-size: 32px;
        }
      }
    }

    h2 {
      font-size: 20px;
      margin: 20px 0 0;

      @include respond-above(md) {
        font-size: 22px;
        margin: 30px 0 0;
      }

      &:lang(ja) {
        font-size: 18px;
        margin: 5px 0 0;

        @include respond-above(md) {
          font-size: 22px;
          margin: 25px 0 0;
        }
      }
    }

    h3 {
      font-size: 16px;
      margin: 15px 0 20px;

      &:lang(ja) {
        font-size: 14px;
        margin: 10px 0;

        @include respond-above(md) {
          font-size: 16px;
          margin: 5px 0 20px;
        }
      }
    }

    p {
      font-size: 16px;
      text-align: left;

      &:lang(ja) {
        font-size: 14px;

        @include respond-above(md) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
