<template>
  <router-link :to="getTo()">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LocalizedLink',
  props: {
    to: String,
  },
  methods: {
    getTo() {
      const { locale } = this.$i18n
      if (locale !== 'en') {
        if (this.to === '/' || this.to === `/${locale}`) {
          return `/${locale}`
        }
        return `/${locale}${this.to}`
      }
      return `${this.to}`
    },
  },
}
</script>
