<template>
  <div class="story-list">
    <ul>
      <li v-for="story in stories" :key="story.id">
        <LocalizedLink :to="`/stories/${story.id}`">
          <div class="picture">
            <img v-if="story.image && story.image.url" :src="story.image.url">
          </div>
          <div class="info">
            <h2 :class="{placeholder: !story.header}">{{ story.header }}</h2>
          </div>
          <div class="jobs">
            <p :class="{placeholder: !story.previous_job}">{{ story.previous_job }}</p>
            <p :class="{placeholder: !story.current_job}">{{ story.current_job }}</p>
          </div>
          <div class="summary">
            <p :class="{placeholder: !story.summary}">{{ story.summary }}</p>
          </div>
        </LocalizedLink>
      </li>
    </ul>
  </div>
</template>

<script>
import LocalizedLink from '@/components/LocalizedLink.vue'

export default {
  name: 'StoryList',
  props: {
    stories: Array,
  },
  components: { LocalizedLink },
}
</script>

<style lang="scss" scoped>
.story-list {
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 16px;

  @include respond-above(md) {
    padding: 0;
  }

  ul {
    @include clearfix();

    list-style: none;
    margin: 0 -16px 0;
    padding: 0;

    @include respond-above(md) {
      margin: 0 -35px 0;
    }

    li {
      display: inline-block;
      padding: 16px 0;
      vertical-align: top;
      width: 100%;

      @include respond-above(md) {
        padding: 35px;
        width: 50%;
      }

      a {
        background-color: $plate-bg-dark;
        border-radius: 25px;
        color: $text-color;
        display: block;
        margin: 0;
        padding: 22px 16px 10px;
        position: relative;
        text-decoration: none;

        @include respond-above(md) {
          padding: 30px 24px 10px;
        }

        &:before,
        &:after {
          background-color: #1c4287;
          mask: url('../assets/form-tab-tl.svg') no-repeat 50% 50%;
          content: ' ';
          width: 25px;
          height: 25px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:after {
          mask: url('../assets/form-tab-tr.svg') no-repeat 50% 50%;
          left: auto;
          right: 0;
        }
      }
    }
  }

  .picture {
    background-color: #d1d1d1;
    height: 56px;
    left: 16px;
    position: absolute;
    top: 22px;
    width: 56px;

    @include respond-above(md) {
      height: 72px;
      left: 24px;
      top: 30px;
      width: 72px;
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  .info {
    margin-bottom: 10px;
    padding-left: 72px;
    position: relative;
    min-height: 56px;

    @include respond-above(md) {
      margin-bottom: 16px;
      min-height: 72px;
      padding-left: 88px;
    }

    h2 {
      font-size: 16px;
      line-height: 160%;
      margin: 0;
      overflow: hidden;

      @include respond-above(md) {
        font-size: 22px;
        max-height: 70px;
      }

      &.placeholder {
        height: 20px;
        width: 90%;
      }
    }
  }

  .jobs {
    @include clearfix;

    position: relative;

    &:before {
      background: url('../assets/story-arrow.png') no-repeat center center;
      background-size: 24px auto;
      content: ' ';
      height: 24px;
      left: 50%;
      position: absolute;
      top: 0;
      width: 24px;
      margin-left: -12px;
    }

    p {
      float: left;
      font-size: 12px;
      font-weight: 700;
      margin: 0 20px 0 0;
      overflow: hidden;
      padding: 00;
      width: calc(50% - 20px);

      @include respond-above(md) {
        font-size: 16px;
        max-height: 58px;
      }

      &:last-child {
        margin-left: 20px;
        margin-right: 0;
      }

      &.placeholder {
        height: 24px;
      }
    }
  }

  .summary {
    &:before,
    &:after {
      background-color: #1c4287;
      bottom: 0;
      content: ' ';
      height: 25px;
      left: 0;
      mask: url('../assets/form-tab-tl.svg') no-repeat 50% 50%;
      position: absolute;
      transform: rotate(-90deg);
      width: 25px;
    }

    &:after {
      mask: url('../assets/form-tab-tr.svg') no-repeat 50% 50%;
      left: auto;
      right: 0;
      transform: rotate(90deg);
    }

    p {
      font-weight: 400;
      margin: 10px 0 15px;
      overflow: hidden;

      @include respond-above(md) {
        font-size: 16px;
        max-height: 86px;
      }

      &.placeholder {
        height: 50px;
        width: 100%;
      }
    }
  }
}
</style>
