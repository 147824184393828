<template>
  <main>
    <section class="not-found">
      <div>
        <h1>Page not found</h1>
        <p>
          Sorry, the page you are looking couldn't be found. The link might be broken or not exist.
        </p>
        <p><LocalizedLink to="/">Back to start page</LocalizedLink>
        <div class="clouds">
          <div class="cloud cloud-b cloud-1" />
          <div class="cloud cloud-b cloud-2" />
          <div class="cloud cloud-a cloud-3" />
          <div class="cloud cloud-a cloud-4" />
          <div class="cloud cloud-a cloud-5" />
          <div class="cloud cloud-b cloud-6" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import LocalizedLink from '@/components/LocalizedLink.vue'

export default {
  name: 'NotFound',
  metaInfo: {
    title: 'Page not found',
  },
  components: {
    LocalizedLink,
  },
}
</script>

<style lang="scss" scoped>
.not-found {
  padding: 20px 16px;

  @include respond-above(lg) {
    padding: 90px 16px;
  }

  > div {
    background: url('../assets/illustration-intro.svg') no-repeat center bottom;
    background-size: auto 272px;
    margin: 0 auto;
    max-width: $content-width + 50px;
    padding: 0 0 300px;
    position: relative;

    @include respond-above(lg) {
      background-position: right -50px center;
      background-size: auto 384px;
      min-height: 444px;
      padding: 90px 0;
    }
  }

  .clouds {
    .cloud {
      position: absolute;
    }

    .cloud-a {
      background: transparent url('../assets/illustration-cloud-a.svg') no-repeat center center;
      background-size: 60px 56px;
      height: 60px;
      width: 56px;
    }

    .cloud-b {
      background: transparent url('../assets/illustration-cloud-b.svg') no-repeat center center;
      background-size: 84px 68px;
      height: 68px;
      width: 84px;
    }

    .cloud-1 {
      right: 5px;
      bottom: 220px;

      @include respond-above(lg) {
        right: -150px;
        bottom: 310px;
      }
    }

    .cloud-2 {
      left: -54px;
      bottom: 208px;

      @include respond-above(lg) {
        left: auto;
        right: 300px;
        bottom: 270px;
      }
    }

    .cloud-3 {
      left: 50%;
      bottom: 126px;

      @include respond-above(lg) {
        bottom: 148px;
      }
    }

    .cloud-4 {
      display: none;

      @include respond-above(lg) {
        display: block;
        right: 20px;
        bottom: 100px;
      }
    }

    .cloud-5 {
      display: none;

      @include respond-above(lg) {
        display: block;
        left: -180px;
        bottom: 300px;
      }
    }

    .cloud-6 {
      display: none;

      @include respond-above(lg) {
        display: block;
        left: -30px;
        bottom: 55px;
      }
    }
  }
}
</style>
