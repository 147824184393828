<template>
  <div class="form">
    <form id="form-workers" @submit.prevent="submit" action="/sendmail" method="post">
      <div class="form-group form-group-name" :class="{'form-error': errors.name}">
        <label for="firstName">{{ $t('name') }}</label>
        <input
          type="text"
          id="firstName"
          v-model="form.firstName"
          :placeholder="$t('firstname-ph')"
        >
        <input type="text" id="lastName" v-model="form.lastName" :placeholder="$t('lastname-ph')">
        <span class="error-msg" v-if="errors.name">{{ $t('name-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.company}">
        <label for="company">{{ $t('company') }}</label>
        <input type="text" id="company" v-model="form.company" :placeholder="$t('company-ph')">
        <span class="error-msg" v-if="errors.company">{{ $t('company-error') }}</span>
      </div>
      <div class="form-group" :class="{'form-error': errors.email}">
        <label for="email">{{ $t('email') }}</label>
        <input type="text" id="email" v-model="form.email" :placeholder="$t('email-ph')">
        <span class="error-msg" v-if="errors.email">{{ $t('email-error') }}</span>
      </div>
      <div class="form-group">
        <label for="phone">{{ $t('phone') }} <span>{{ $t('optional') }}</span></label>
        <input type="text" id="phone" v-model="form.phone" :placeholder="$t('phone-ph')">
      </div>
      <div class="form-group" :class="{'form-error': errors.message}">
        <label for="message">{{ $t('message') }}</label>
        <textarea id="message" v-model="form.message" rows="6" />
        <span class="error-msg" v-if="errors.message">{{ $t('message-error') }}</span>
      </div>
      <div class="form-group form-group-policy">
        <i18n path="privacy-text" tag="p" for="privacy-policy">
          <LocalizedLink to="/privacy-policy">{{ $t('privacy-policy') }}</LocalizedLink>
        </i18n>
      </div>
      <div class="form-group">
        <button type="submit" class="button">{{ $t('contact-us') }}</button>
      </div>
    </form>
  </div>
</template>

<i18n>
{
  "en": {
    "name": "Name",
    "name-error": "Name is required",
    "firstname-ph": "First name",
    "lastname-ph": "Last name",
    "company": "Company",
    "company-error": "Company is required",
    "company-ph": "Company, Inc.",
    "email": "Email",
    "email-error": "A valid email address is required",
    "email-ph": "name@email.com",
    "phone": "Phone number",
    "phone-ph": "+1 111-111-1111",
    "message": "Free text",
    "message-error": "Message is required",
    "optional": "(Optional)",
    "privacy-text": "Submitting this registration form means you’re okay with our {0}.",
    "privacy-policy": "Privacy Policy",
    "contact-us": "Contact us"
  },
  "ja": {
    "name": "氏名",
    "name-error": "名前が必要です",
    "firstname-ph": "名",
    "lastname-ph": "姓",
    "company": "企業名",
    "company-error": "企業名が必要です",
    "company-ph": "株式会社◯◯",
    "email": "メールアドレス",
    "email-error": "有効なメールアドレスが必要です",
    "email-ph": "name@email.com",
    "phone": "電話番号",
    "phone-ph": "090-123-4567",
    "message": "自由記述欄",
    "message-error": "自由記述欄が必要です",
    "optional": "(任意)",
    "privacy-text": "登録フォームを送信した時点で、Career Craftの{0}に同意したものとみなされます。",
    "privacy-policy": "プライバシーポリシー",
    "contact-us": "送信"
  }
}
</i18n>

<script>
import LocalizedLink from './LocalizedLink.vue'
import config from '../helpers/config'

export default {
  name: 'FormWorkers',
  components: { LocalizedLink },
  computed: {
    locale() { return this.$i18n.locale },
  },
  data() {
    return {
      errors: {
        name: false,
        company: false,
        email: false,
        message: false,
      },
      form: {
        type: 'Company',
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phone: null,
        message: null,
      },
    }
  },
  methods: {
    validate() {
      this.errors.name = (!this.form.firstName || !this.form.lastName)
      this.errors.company = !this.form.company
      this.errors.email = (!this.form.email
        || !config.validate.email.test(String(this.form.email).toLowerCase()))
      this.errors.message = !this.form.message
      return (
        !this.errors.name
        && !this.errors.company
        && !this.errors.email
        && !this.errors.message
      )
    },
    submit() {
      if (this.validate()) {
        this.$store.dispatch('sendMail', { form: this.form, locale: this.locale }).then((res) => {
          if (res.data.sent) {
            this.$router.push(`${this.locale !== 'en' ? `/${this.locale}` : ''}/signup/thanks`)
          }
          // TODO: Handle error
          // throw new Error('Error')
        })
      }
    },
  },
}
</script>
