<template>
  <div class="social-media">
    <ul>
      <li>
        <a
          href="https://www.linkedin.com/company/career-craft-jp/"
          title="LinkedIn"
          class="linkedin"
        >
          LinkedIn
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/careercraft.jp/"
          title="Facebook"
          class="facebook"
        >
          Facebook
        </a>
      </li>
      <li>
        <a
          href="mailto:info@careercraft.jp"
          title="Send email to info@careercraft.jp"
          class="contact"
        >
          Email
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialMedia',
}
</script>

<style lang="scss" scoped>
.social-media {
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 12px;

      a {
        display: block;
        height: 24px;
        opacity: 0.5;
        overflow: hidden;
        text-indent: -9999em;
        transition: opacity .2s ease-in;
        width: 24px;

        &:hover {
          opacity: 1;
        }

        &.linkedin {
          background:
            transparent url('../assets/social-icon-linkedin.svg') no-repeat center center;
          background-size: 18px 18px;
        }

        &.facebook {
          background:
            transparent url('../assets/social-icon-facebook.svg') no-repeat center center;
          background-size: 12px 20px;
        }

        &.contact {
          background:
            transparent url('../assets/social-icon-contact.svg') no-repeat center center;
          background-size: 20px 15px;
        }
      }
    }
  }
}

header .social-media {
  @include respond-above(md) {
    display: none;
  }
}
</style>
