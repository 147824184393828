<template>
  <main class="process">
    <article v-html="htmlContent" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import marked from 'marked'

export default {
  name: 'Process',
  metaInfo: {
    title: 'The Process',
    meta: [
      { property: 'og:title', content: 'The Process | Career Craft' },
    ],
  },
  computed: {
    locale() { return this.$i18n.locale },
    htmlContent() {
      const currentPage = this.getPageBySlug('process') || {}
      let content = currentPage.content || ''
      if (this.locale !== 'en') {
        content = currentPage[`content_${this.locale}`] || ''
      }
      return marked(content)
    },
    ...mapGetters(['getPageBySlug']),
  },
}
</script>

<style lang="scss" scoped>
.process {
  article {
    margin: 0 auto;
    max-width: $article-width;
    padding: 0 16px 40px;

    @include respond-above(lg) {
      // padding: 60px 16px 90px;
      padding: 60px 16px 0;
    }

    &::v-deep {
      h1 {
        font-size: 22px;
        line-height: 1.4;
        text-align: center;
        margin: 20px 0 25px;

        @include respond-above(md) {
          font-size: 32px;
          letter-spacing: -0.5px;
        }

        &:lang(ja) {
          font-size: 20px;
          line-height: 1.6;

          @include respond-above(md) {
            font-size: 32px;
          }
        }
      }

      ol {
        counter-reset: li;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          padding: 20px 0 0;

          @include respond-above(md) {
            padding: 35px 0 0;
          }

          h2 {
            display: block;
            float: right;
            font-size: $font-size-h4;
            margin: 0 0 10px;
            line-height: 1.4;
            width: calc(100% - 35px);

            @include respond-above(md) {
              font-size: 24px;
              width: calc(100% - 45px);
            }

            &:lang(ja) {
              font-size: 18px;
              line-height: 1.6;

              @include respond-above(md) {
                font-size: 20px;
              }
            }
          }

          p {
            margin: 10px 0 25px;

            @include respond-above(md) {
              margin: 15px 0 35px;
            }

            &:lang(ja) {
              @include respond-above(md) {
                font-size: 16px;
              }
            }

            &:before {
              content: '';
              clear: both;
              display: table;
            }
          }

          &:before {
            counter-increment: li;
            content: counter(li, decimal-leading-zero);
            line-height: 1.4;
            font-size: 22px;
            font-family: $font-family-monospace;
            font-weight: $font-size-header-weight;

            @include respond-above(md) {
              font-size: 24px;
            }
          }

          &:after {
            background: url('../assets/icon-process-arrow.svg') no-repeat 0 0;
            content: '';
            display: block;
            height: 16px;
            margin: 0 auto;
            padding: 0;
            width: 14px;
          }

          &:last-child:after {
            display: none;
          }
        }

        li:lang(ja) {
          &:before {
            font-size: 20px;

            @include respond-above(md) {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
