<template>
  <footer>
    <div class="footer-inner">
      <div class="footer-content">
        <nav class="footer-nav">
          <SocialMedia />
          <ul class="footer-menu">
            <li><LocalizedLink to="/signup?t=1">{{ $t('contact-us') }}</LocalizedLink></li>
            <li><LocalizedLink to="/privacy-policy">{{ privacyTitle }}</LocalizedLink></li>
            <li class="lang-link"><LanguageSwitch to="ja">JA</LanguageSwitch></li>
            <li class="lang-link"><LanguageSwitch to="en">EN</LanguageSwitch></li>
          </ul>
        </nav>
        <p class="copy">&copy; Career Craft, Inc.</p>
      </div>
    </div>
  </footer>
</template>

<i18n>
{
  "en": {
    "contact-us": "Contact us"
  },
  "ja": {
    "contact-us": "お問い合わせ"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import LanguageSwitch from './LanguageSwitch.vue'
import LocalizedLink from './LocalizedLink.vue'
import SocialMedia from './SocialMedia.vue'

export default {
  name: 'Footer',
  components: {
    LanguageSwitch,
    LocalizedLink,
    SocialMedia,
  },
  computed: {
    locale() { return this.$i18n.locale },
    privacyTitle() {
      const page = this.getPageBySlug('privacy-policy') || {}
      if (this.locale !== 'en') {
        return page[`title_${this.locale}`]
      }
      return page.title
    },
    ...mapGetters(['getPageBySlug']),
  },
}
</script>

<style lang="scss" scoped>
footer {
  display: table-row;
  height: 0;

  .footer-inner {
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    padding: 25px 16px;
    text-align: center;
  }

  .footer-content {
    @include clearfix;

    margin: 0 auto;
    max-width: 1404px;
    position: relative;
    width: 100%;
  }

  .copy {
    @include respond-above(lg) {
      float: left;
      margin: 0;
    }
  }

  .footer-nav {
    margin: 15px 0;

    @include respond-above(lg) {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      padding-left: 210px;
      margin: 0;
    }

    .social-media {
      @include respond-above(lg) {
        display: block;
        float: right;
      }
    }

    .footer-menu {
      display: inline-block;
      line-height: 1.5;
      margin: 10px 0 5px;

      @include respond-above(lg) {
        float: left;
        margin: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: block;
        padding: 7px;

        @include respond-above(lg) {
          display: inline-block;
          padding: 0 10px;
        }

        &:lang(ja) {
          font-family: $font-family-japanese;
        }

        &:nth-child(2) {
          padding: 7px 7px 14px;

          @include respond-above(lg) {
            padding: 0 30px 0 10px;
          }
        }

        &.lang-link {
          display: inline-block;

          &:lang(ja) {
            font-family: $font-family-monospace;
          }
        }

        a {
          color: $text-color;
          display: inline-block;
          opacity: 0.5;
          text-decoration: none;
          transition: opacity 0.2s ease-out;

          @include respond-above(lg) {
            display: inline;
          }

          &:hover,
          &.router-link-exact-active {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
