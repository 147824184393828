<template>
  <main class="faq">
    <Article :content="content" />
    <div v-if="questions" class="questions">
      <Question v-for="question in questions" :key="question.id" :question="question" />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'
import Question from '@/components/Question.vue'

export default {
  name: 'Faq',
  metaInfo: {
    title: 'FAQ',
    meta: [
      { property: 'og:title', content: 'FAQ | Career Craft' },
    ],
  },
  components: { Article, Question },
  computed: {
    locale() { return this.$i18n.locale },
    currentPage() { return this.getPageBySlug('faq') || {} },
    content() {
      if (this.locale !== 'en') {
        return this.currentPage[`content_${this.locale}`]
      }
      return this.currentPage.content
    },
    questions() {
      const questions = this.getFaqsByLang(this.locale)
      if (questions.length) {
        return questions
      }
      return [
        { question: '' },
        { question: '' },
        { question: '' },
        { question: '' },
        { question: '' },
      ]
    },
    ...mapGetters(['getFaqsByLang', 'getPageBySlug']),
  },
  async serverPrefetch() {
    await this.$store.dispatch('loadFaqs', { lang: this.locale })
  },
  beforeMount() {
    if (!this.questions || !this.questions[0].question || this.questions[0].question === '') {
      this.$store.dispatch('loadFaqs', { lang: this.locale })
    }
  },
}
</script>

<style lang="scss" scoped>
.questions {
  padding: 0 16px;
  margin: 0 auto;
  max-width: $article-width;

  @include respond-above(lg) {
    padding: 0 16px;
  }
}
</style>
