import axios from 'axios'

const instance = axios.create({ baseURL: process.env.VUE_APP_API_URL })

const fetchEmployees = () => instance.get('/employees?_sort=sort:ASC')
const fetchFaqs = (lang) => instance.get(`/faqs?language=${lang}&_sort=sort:ASC`)
const fetchPages = () => instance.get('/pages')
const fetchRoles = () => instance.get('/work-roles?_sort=sort:ASC,name:ASC')
const fetchStories = () => instance.get('/stories?_sort=sort:ASC,name:ASC')

const sendMail = (form, locale) => {
  const formData = new FormData()
  if (form.file) {
    formData.append('file', form.file)
  }
  formData.append('type', form.type || '')
  formData.append('name', `${form.firstName || ''} ${form.lastName || ''}`)
  formData.append('company', form.company || '')
  formData.append('role', form.role || '')
  formData.append('country', form.country || '')
  formData.append('japaneseLevel', form.japaneseLevel || '')
  formData.append('englishLevel', form.englishLevel || '')
  formData.append('email', form.email || '')
  formData.append('phone', form.phone || '')
  formData.append('linkedin', form.linkedin || '')
  formData.append('message', form.message || '')
  formData.append('locale', locale || '')
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: formData,
    url: '/sendmail',
  }
  return axios(options)
}

export default {
  fetchEmployees,
  fetchFaqs,
  fetchPages,
  fetchRoles,
  fetchStories,
  sendMail,
}
