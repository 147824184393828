<template>
  <router-link :to="newPath">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LanguageSwitch',
  props: {
    to: String,
  },
  data() {
    return {
      newPath: '/',
    }
  },
  watch: {
    $route() {
      this.loadNewPath()
    },
  },
  methods: {
    loadNewPath() {
      const { locale } = this.$i18n
      const { path } = this.$router.currentRoute

      // english
      if (locale === 'en') {
        if (this.to === 'en') {
          this.newPath = path === '/' ? '/' : path
        } else {
          this.newPath = path === '/' ? `/${this.to}` : `/${this.to}${path}`
        }
      }

      // other lang
      if (locale !== 'en') {
        if (this.to === 'en') {
          this.newPath = path === `/${locale}` ? '/' : path.replace(`/${locale}/`, '/')
        } else {
          this.newPath = path === `/${locale}` ? `/${this.to}` : path
        }
      }
    },
  },
  beforeMount() {
    this.loadNewPath()
  },
}
</script>
