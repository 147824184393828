<template>
  <main class="signup">
    <Article :content="content" />
    <nav class="tabs">
      <ul>
        <li>
          <button @click="toggleTab(0)" :class="{active: this.currentTab === 0}">
            {{ $t('work') }}
          </button>
        </li>
        <li>
          <button @click="toggleTab(1)" :class="{active: this.currentTab === 1}">
            {{ $t('workers') }}
          </button>
        </li>
      </ul>
    </nav>
    <section class="work" v-if="this.currentTab === 0">
      <FormWork :roles="roles" />
    </section>
    <section class="workers" v-if="this.currentTab === 1">
      <FormWorkers />
    </section>
  </main>
</template>

<i18n>
{
  "en": {
    "work": "Work",
    "workers": "Workers"
  },
  "ja": {
    "work": "転職したい",
    "workers": "採用したい"
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'
import FormWork from '@/components/FormWork.vue'
import FormWorkers from '@/components/FormWorkers.vue'

export default {
  name: 'Signup',
  metaInfo: {
    title: 'Sign up',
    meta: [
      { property: 'og:title', content: 'Sign up | Career Craft' },
    ],
  },
  components: { Article, FormWork, FormWorkers },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    locale() { return this.$i18n.locale },
    content() {
      const currentPage = this.getPageBySlug('signup') || {}
      if (this.locale !== 'en') {
        return currentPage[`content_${this.locale}`] || ''
      }
      return currentPage.content || ''
    },
    roles() {
      const roles = this.getRoles()
      return roles.reduce((res, item) => ({
        ...res, [item.type]: [...(res[item.type] || []), item],
      }), {})
    },
    ...mapGetters(['getPageBySlug', 'getRoles']),
  },
  beforeMount() {
    if (!this.roles || !this.roles.length) {
      this.$store.dispatch('loadRoles')
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.t) {
      this.currentTab = parseInt(this.$route.query.t, 10)
    }
  },
  methods: {
    toggleTab(index) {
      this.currentTab = index
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  height: 40px;
  margin: 0 auto;
  max-width: $article-width;

  @include respond-above(md) {
    height: 55px;
  }

  ul {
    @include clearfix();

    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      float: left;
      width: 50%;
      position: relative;
    }
  }

  button {
    background-color: #1c4287;
    border: 0;
    border-radius: 25px 25px 0 0;
    color: #fff;
    cursor: pointer;
    font-family: $font-family-monospace;
    height: 40px;
    width: 100%;

    @include respond-above(md) {
      font-size: 20px;
      height: 55px;
    }

    &:lang(ja) {
      font-family: $font-family-japanese;
      font-size: 18px;
      font-weight: 700;
    }

    &:before,
    &:after {
      background-color: #1c4287;
      mask: url('../assets/form-tab-tl.svg') no-repeat 50% 50%;
      content: ' ';
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      mask: url('../assets/form-tab-tr.svg') no-repeat 50% 50%;
      left: auto;
      right: 0;
    }

    &.active {
      background-color: #fff;
      color: #163161;

      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }
}
</style>
