<template>
  <article v-html="htmlContent" />
</template>

<script>
import marked from 'marked'

export default {
  name: 'Article',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    htmlContent() { return marked(this.content) },
  },
}
</script>

<style lang="scss" scoped>
article {
  margin: 0 auto;
  max-width: $article-width;
  padding: 0 16px;

  @include respond-above(lg) {
    padding: 60px 16px 0;
  }

  &::v-deep {
    h1, h2, h3, h4, h5, h6 {
      &:lang(ja) {
        font-family: $font-family-japanese;
        font-weight: 700;
      }
    }

    h1 {
      font-size: 22px;
      line-height: 1.4;
      text-align: center;
      margin: 20px 0 25px;

      @include respond-above(md) {
        font-size: 32px;
        letter-spacing: -0.5px;
        margin: 20px 0 40px;
      }

      &:lang(ja) {
        font-size: 20px;
        line-height: 1.6;

        @include respond-above(md) {
          font-size: 32px;
        }
      }
    }

    h2, h3, h4 {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      line-height: 1.4;
      margin: 10px 0 5px;
    }

    h2 {
      font-size: $font-size-h3;
      margin: 40px 0 15px;
    }

    h3 {
      font-size: $font-size-h4;
    }

    h4 {
      font-size: 18px;
    }

    ul {
      margin: 0 -20px 0 20px;
      padding: 0 0 0 20px;
      list-style: disc outside;

      li {
        padding: 5px 0 0;
      }
    }

    p {
      margin: 0 0 15px;

      @include respond-above(lg) {
        font-size: 18px;
      }

      &:lang(ja) {
        @include respond-above(md) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
