<template>
  <main class="home">
    <Hero :hero="hero" />
    <Intro :content="content" />
    <Usp :title="usp.title" :features="usp.features" />
    <section v-if="locale === 'ja'" class="stories">
      <h2>転職成功事例</h2>
      <StoryList :stories="stories" />
      <LocalizedLink to="/stories" class="button secondary">全ての事例を見る</LocalizedLink>
    </section>
    <Award :content="award" v-if="award" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Award from '@/components/Award.vue'
import Hero from '@/components/Hero.vue'
import Intro from '@/components/Intro.vue'
import LocalizedLink from '@/components/LocalizedLink.vue'
import StoryList from '@/components/StoryList.vue'
import Usp from '@/components/Usp.vue'

export default {
  name: 'Home',
  metaInfo: {
    meta: [
      { property: 'og:title', content: 'Career Craft' },
    ],
  },
  components: {
    Award,
    Hero,
    Intro,
    LocalizedLink,
    StoryList,
    Usp,
  },
  computed: {
    locale() { return this.$i18n.locale },
    currentPage() { return this.getPageBySlug('home') || {} },
    content() {
      if (this.locale !== 'en') {
        return this.currentPage[`content_${this.locale}`]
      }
      return this.currentPage.content
    },
    meta() { return this.currentPage.meta ? this.currentPage.meta[this.locale] : {} },
    hero() { return (this.meta && this.meta.hero) || {} },
    usp() { return (this.meta && this.meta.usp) || {} },
    stories() {
      const stories = this.getStories()
      if (stories.length) {
        return stories.slice(0, stories.length > 1 ? 2 : 1)
      }
      return [
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
        {
          header: '',
          previousJob: '',
          currentJob: '',
          summary: '',
        },
      ]
    },
    award() {
      const awardPage = this.getPageBySlug('award') || {}
      if (awardPage && awardPage.content) {
        if (this.locale !== 'en') {
          return awardPage[`content_${this.locale}`]
        }
        return awardPage.content
      }
      return null
    },
    ...mapGetters(['getPageBySlug', 'getStories']),
  },
}
</script>

<style lang="scss" scoped>
.home {
  padding-top: 0;

  .stories {
    overflow: hidden;
    padding-bottom: 48px;
    padding-top: 0;

    @include respond-above(md) {
      padding-bottom: 80px;
    }

    h2 {
      margin-top: 10px;
      text-align: center;

      @include respond-above(md) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
