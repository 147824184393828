<template>
  <div class="company-info" v-if="items">
    <h2>{{ $t('overview') }}</h2>
    <dl>
      <template v-for="item in items">
        <dt :key="item.name">{{ item.name }}</dt>
        <dd :key="item.text">
          {{ item.text }}
          <span v-if="item.url">
            <a :href="item.url" title="" target="_blank">Google Maps</a>
          </span>
        </dd>
      </template>
    </dl>
  </div>
</template>

<i18n>
{
  "en": {
    "overview": "Overview"
  },
  "ja": {
    "overview": "会社概要"
  }
}
</i18n>

<script>
export default {
  name: 'CompanyInfo',
  props: {
    items: Array,
  },
}
</script>

<style lang="scss" scoped>
.company-info {
  margin: 0 auto;
  max-width: $article-width;
  padding: 16px 16px 0;

  @include respond-above(lg) {
    padding: 60px 16px 0;
  }

  h2 {
    text-align: center;
  }

  a {
    color: #00a0e4;
  }

  dl {
    @include clearfix();

    padding: 10px 0 0;

    @include respond-above(lg) {
      padding: 30px 0 0;
    }

    dt {
      color: #a2adc0;
      font-size: 14px;
      margin: 0 0 8px;

      @include respond-above(md) {
        color: $text-color;
        float: left;
        font-size: 16px;
        width: 230px;
      }

      &:lang(ja) {
        font-size: 12px;
        margin: 0 0 5px;

        @include respond-above(md) {
          font-size: 16px;
        }
      }
    }

    dd {
      margin: 0 0 22px;
      line-height: 1.4;

      @include respond-above(md) {
        float: left;
        margin: 0 0 30px;
        width: calc(100% - 230px);
      }

      &:lang(ja) {
        line-height: 1.8;

        @include respond-above(md) {
          font-size: 16px;
        }
      }

      span:before {
        content: ' ';
        clear: right;
        display: block;
      }
    }
  }
}
</style>
