<template>
  <main class="privacy">
    <Article :content="content" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Article from '@/components/Article.vue'

export default {
  name: 'Privacy',
  metaInfo: {
    title: 'Privacy Policy',
    meta: [
      { property: 'og:title', content: 'Privacy Policy | Career Craft' },
    ],
  },
  components: { Article },
  computed: {
    locale() { return this.$i18n.locale },
    content() {
      const currentPage = this.getPageBySlug('privacy-policy') || {}
      if (this.locale !== 'en') {
        return currentPage[`content_${this.locale}`] || ''
      }
      return currentPage.content || ''
    },
    ...mapGetters(['getPageBySlug']),
  },
}
</script>
