import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import i18n from './i18n'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'

Vue.config.productionTip = false

const createApp = (context) => {
  // create router and store instances
  const router = createRouter()
  const store = createStore()

  // sync so that route state is available as part of the store
  sync(store, router)

  const app = new Vue({
    data: { url: context ? context.url : '' },
    router,
    store,
    i18n,
    render: (h) => h(App),
  })

  return { app, router, store }
}

// eslint-disable-next-line import/prefer-default-export
export { createApp }
